<template>
  <!--
    Lists the cameraManagementConfigs and lets the user edit them.
  -->
  <Portlet
    :title="$t('cameraManagementConfigDisplayComponent.cameraManagementConfig')"
    icon="cogs"
    class="cameraManagementConfigDisplay"
  >
    <div slot="buttons">
      <font-awesome-icon
        v-if="showReloadButton"
        v-tooltip="$t('configReload.reloadCameraManagementConfig')"
        :class="['alt-pointer color-primary mr-2 mt-3', { 'fa-spin' : reloadLoading }]"
        icon="sync-alt"
        style="vertical-align: middle;"
        @click="reloadConfig()"
      />
      <button
        class="btn btn-sm btn-primary float-right"
        @click="addCameraConfig()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="plus"
        />
        <span>{{ $t('add') }}</span>
      </button>
    </div>
    <LoadingPlaceholder v-if="loading" />

    <template v-else>
      <template v-if="configData">
        <table class="defaultTable">
          <colgroup>
            <col width="50">
            <col>
            <col width="70">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('id') }}</th>
              <th class="d-none d-md-table-cell">
                {{ $t('ipAddress') }}
              </th>
              <th>{{ $t('active') }}</th>
              <th>{{ $t('manufacturer') }}</th>
              <th>{{ $t('model') }}</th>
              <th class="d-none d-lg-table-cell">
                {{ $t('cameraManagementConfigDisplayComponent.panTiltIpAddress') }}
              </th>
              <th class="d-none d-lg-table-cell">
                CameraOperator Host IP
              </th>
              <th class="d-none d-lg-table-cell">
                Head
              </th>
              <th>{{ $t('options') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(cameraConfig, index) in configData.cameraConfigs"
              :key="`CameraManagementConfigDisplay-cameraConfig-tableRow-${ index }`"
            >
              <td>{{ cameraConfig.id }}</td>
              <td class="d-none d-md-table-cell">
                {{ cameraConfig.ipAddress }}
              </td>
              <td>
                <toggle-button
                  v-model="cameraConfig.active"
                  :width="32"
                  :height="17"
                />
              </td>
              <td>{{ cameraConfig.manufacturer }}</td>
              <td>{{ cameraConfig.model }}</td>
              <td class="d-none d-lg-table-cell">
                {{ cameraConfig.panTiltConfig.ipAddress }}
              </td>
              <td class="d-none d-lg-table-cell">
                {{ cameraConfig.cameraOperatorHostIp }}
              </td>
              <td class="d-none d-lg-table-cell">
                {{ cameraConfig.ptHeadType }}
              </td>
              <td>
                <div class="d-none d-md-block btn-group btn-group-sm">
                  <button
                    class="btn btn-sm btn-primary"
                    @click="editCameraConfig(cameraConfig.id)"
                  >
                    <font-awesome-icon
                      class="mr-2"
                      icon="edit"
                    />
                    <span>{{ $t('edit') }}</span>
                  </button>
                  <button
                    class="btn btn-sm btn-secondary"
                    @click="openConfirmRemoveModal(cameraConfig.id)"
                  >
                    <font-awesome-icon
                      class="mr-2 gray"
                      icon="trash"
                    />
                    <span>{{ $t('remove') }}</span>
                  </button>
                </div>
                <div class="d-md-none">
                  <button
                    class="btn btn-sm btn-primary btn-block"
                    @click="editCameraConfig(cameraConfig.id)"
                  >
                    <font-awesome-icon
                      class="mr-2"
                      icon="edit"
                    />
                    <span>{{ $t('edit') }}</span>
                  </button>
                  <button
                    class="btn btn-sm btn-secondary btn-block"
                    @click="openConfirmRemoveModal(cameraConfig.id)"
                  >
                    <font-awesome-icon
                      class="mr-2 gray"
                      icon="trash"
                    />
                    <span>{{ $t('remove') }}</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </template>

      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>

      <hr>

      <router-link
        :to="{ path: `/installation/${ installationId }/tracker/config` }"
        class="btn btn-secondary float-left"
      >
        <font-awesome-icon
          class="mr-1"
          icon="caret-left"
        />
        <span>
          {{ $t('back') }}
        </span>
      </router-link>
      <button
        v-if="updated"
        class="btn btn-primary float-right"
        @click="setConfig()"
      >
        <font-awesome-icon
          class="mr-1"
          icon="upload"
        />
        <span>
          {{ $t('save') }}
        </span>
      </button>
      <div
        v-if="updated"
        class="red float-right mr-4"
      >
        <h3><span class="badge badge-danger">Unsaved changes detected</span></h3>
      </div>
      <div class="clearfix" />

      <Sidebar
        :show-sidebar="showSidebarBoolean"
        @close="hideSidebar"
      >
        <component
          :is="currentComponent"
          v-if="showSidebarBoolean"
          :installation-id="installationId"
          :current-id="currentId"
          :lane-number="laneNumber"
          @getConfig="getConfig()"
          @setReloadButton="setReloadButton"
          @hide="hideSidebar"
        />
      </Sidebar>

      <SweetModal
        ref="confirmDelete"
        :title="$t('cameraManagementConfigDisplayComponent.deleteCamera')"
        icon="warning"
        blocking
        class="overflowHidden"
      >
        <p>{{ $t('cameraManagementConfigDisplayComponent.areYouSureYouWantToDelete') }}</p>
        <button
          slot="button"
          class="btn btn-secondary float-left mb-3"
          @click="$refs.confirmDelete.close()"
        >
          <font-awesome-icon
            class="mr-2 gray"
            icon="times"
          />
          <span>{{ $t('cancel') }}</span>
        </button>
        <button
          slot="button"
          class="btn btn-danger float-right mb-3"
          @click="removeCameraConfig()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="trash"
          />
          <span>{{ $t('delete') }}</span>
        </button>
        <div class="clearfix" />
      </SweetModal>
    </template>
  </Portlet>
</template>

<script>
import Sidebar from '@/components/Base/Sidebar.vue';

import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { errorMixin } from '@/mixins/errorMixin.js';
import { SweetModal, SweetModalTab } from 'sweet-modal-vue';

export default {
  name: 'CameraManagementConfigDisplay',
  components: {
    Sidebar,
    CameraManagementConfigFormAdd: () => import('@/components/Config/CameraManagement/CameraManagementConfigFormAdd.vue'),
    CameraManagementConfigFormEdit: () => import('@/components/Config/CameraManagement/CameraManagementConfigFormEdit.vue'),
    SweetModal,
    SweetModalTab
  },
  mixins: [
    dateTimeMixin,
    errorMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return{
      loading: true,
      configData: null,
      showSidebarBoolean: false,
      currentId: null,
      currentComponent: null,
      indexToDelete: null,
      originalConfigData: null,
      showReloadButton: false,
      reloadLoading: false
    }
  },
  computed: {
    updated () {
      return JSON.stringify(this.configData) !== JSON.stringify(this.originalConfigData);
    }
  },
  created () {
    this.getConfig();
  },
  methods: {
//#region Helper
    openConfirmRemoveModal (index) {
      this.indexToDelete = index;
      this.$refs.confirmDelete.open();
    },
    removeCameraConfig () {
      this.$refs.confirmDelete.close();
      this.deleteConfig();
    },
    addCameraConfig () {
      this.currentComponent = 'CameraManagementConfigFormAdd';
      this.showSidebar(null);
    },
    editCameraConfig (value) {
      this.currentComponent = 'CameraManagementConfigFormEdit';
      this.showSidebar(value);
    },
    setReloadButton (value) {
      this.showReloadButton = value;
    },
//#endregion

//#region Sidebar
    showSidebar (value) {
      this.currentId = value;
      this.showSidebarBoolean = true;
    },
    hideSidebar () {
      this.showSidebarBoolean = false;
      this.currentId = null;
      this.currentComponent = null;
    },
//#endregion

//#region API-calls
    setConfig () {
      let url = `/Config/UpdateCameraManagementConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, this.configData)
        .then((response) => {
          if (response && response.status == 204) {
            this.setReloadButton(true);
            this.$snotify.error(this.$t('configReload.failedReload'));
          } else {
            this.setReloadButton(false);
          }
          this.$snotify.success("The config was successfully saved.");
          this.originalConfigData = JSON.parse(JSON.stringify(this.configData))
          this.error_clear();
          this.$emit('getConfig');
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    getConfig () {
      this.currentId = 0;
      let url = `/Config/GetCameraManagementConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.configData = response.data;
          this.originalConfigData = JSON.parse(JSON.stringify(this.configData));
        })
        .finally(() => {
          this.loading = false;
          this.showSidebarBoolean = false;
          window.$('[data-toggle="tooltip"]').tooltip();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteConfig () {
      let url = `/Config/DeleteCameraManagementConfig?cameraConfigId=${ this.indexToDelete }&installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.delete(url)
        .then((response) => {
          if (response && response.status == 204) {
            this.setReloadButton(true);
            this.$snotify.error(this.$t('configReload.failedReload'));
          } else {
            this.setReloadButton(false);
          }
          this.getConfig();
        })
        .finally(() => {
          this.indexToDelete = null;
          this.$refs.confirmDelete.close();
        });
    },
    reloadConfig () {
      this.reloadLoading = true;
      let url = `/Config/ReloadCameraManagementConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url)
        .then((response) => {
          if (response && response.status == 204) {
            this.setReloadButton(true);
            this.$snotify.error(this.$t('configReload.failedReload'));
          } else {
            this.setReloadButton(false);
            this.getConfig();
          }
        })
        .finally(() => {
          this.reloadLoading = false;
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
//#endregion
  }
}
</script>